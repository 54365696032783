import React from "react"

import Layout from "../components/layout";
import SEO from "../components/seo";

import ConsumerDisclosures from '../components/consumerDisclosures';

const DisclosuresPage = () => (
    <Layout>
      <SEO title="Consumer Disclosures" />
      <br />
    
      <ConsumerDisclosures />
    </Layout>
  )
  
  export default DisclosuresPage